

.react-pdf__Page__canvas {
  margin:0 auto;
  padding: 0;
  width: 100% !important;
}

input[type="file"] {
  display: none;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}